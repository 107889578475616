<script setup lang="ts">
import useCustomSearchVisibility from "~/composables/useCustomSearchVisibility";

const {observeElement, disconnectObserver} = useCustomSearchVisibility();

const props = defineProps<{
    content: any;
}>();

const customSearchInput = ref<HTMLInputElement|null>(null);

const submitSearch = () => {
    navigateTo({
        path: "/search",
        query: { query: customSearchInput.value?.value }
    });
}

onMounted(() => {
    observeElement(customSearchInput.value);
});

onUnmounted(() => {
    disconnectObserver();
});
</script>

<template>
    <div class="container px-[20px] relative">
        <div
            class="element-custom-search bg-white px-[12px] pb-[26px] pt-[19px] lg:p-[35px] rounded-3 absolute absolute-center z-1 top-[-30px]"
            style="box-shadow: 0px 6px 10px #0000000D;"
        >
            <div class="text-[18px] lg:text-[25px] mb-[25px] font-semibold w-full text-center">{{content.config.title.value}}</div>
            <div class="relative">
                <input
                    ref="customSearchInput"
                    type="search"
                    autocomplete="off"
                    :placeholder="$t('search.searchPlaceholder')"
                    class="w-full bg-brand-gray text-[15px] lg:text-[18px] text-ellipsis font-normal outline-none pb-[17px] pt-[20px] px-[30px] rounded-full border-none"
                    @keydown.enter="submitSearch"
                />
                <img
                    src="/search.svg" height="20"
                    :alt="$t('search.searchPlaceholder')"
                    class="absolute top-[50%] right-[30px] transform -translate-y-1/2 cursor-pointer"
                    @click.prevent="submitSearch"
                />
            </div>
        </div>
    </div>
</template>
<style scoped>

.element-custom-search {
    width: calc(100% - 40px);

    @media (min-width: 1024px) {
        width: 50%;
    }
}

</style>
